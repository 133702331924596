module.exports = [{
      plugin: require('../node_modules/@raae/gatsby-remark-oembed/gatsby-browser.js'),
      options: {"plugins":[],"usePrefix":["embed"],"providers":{"include":["YouTube"],"settings":{"Instagram":{"hidecaption":true}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/opt/build/repo/src/components/Layout/index.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"nu me","short_name":"nu me","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","icon":"static/images/site.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"6e90ef3d27360cc069bbdbba14eb3162"},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"coetl3XXo11hsTOkqg8Bd5gAYz7Pjxo8","devKey":"coetl3XXo11hsTOkqg8Bd5gAYz7Pjxo8","trackPage":false,"delayLoad":false,"delayLoadTime":1000},
    }]
