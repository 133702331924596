// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-mdx": () => import("./../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-index-mdx": () => import("./../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-redirect-mdx": () => import("./../src/pages/redirect.mdx" /* webpackChunkName: "component---src-pages-redirect-mdx" */),
  "component---src-pages-thanks-mdx": () => import("./../src/pages/thanks.mdx" /* webpackChunkName: "component---src-pages-thanks-mdx" */),
  "component---src-pages-try-it-mdx": () => import("./../src/pages/try-it.mdx" /* webpackChunkName: "component---src-pages-try-it-mdx" */)
}

